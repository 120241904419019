import React from 'react';
import { InfoSection } from '../../components';
import { homeObjOne,formdiv ,OnResteEnContact,OnResteEnContactPrecisions} from './Data';
import { Form, Input, TextArea, Button,Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import '../../index.css';
import{useState} from 'react'

function SignUp() {
 {/* --- handleOnSubmit method --- */}

const submitForm = (e) => {
    // Initiate Variables With Form Content
    var name = e.target[0].value;
    var surname = e.target[1].value;
    var email = e.target[2].value;
    var phone = e.target[3].value;
    var message = e.target[4].value;
    // var demodate = $("#rdv").val();
    // let data = `name=${name}&surname=${surname}&email=${email}&phone=${phone}&msg_subject=${msg_subject}&message=${message}&demo=${demodate}`;
    // `name=${name}&surname=${surname}&email=${email}&phone=${phone}&message=${message}`;
    let data = new URLSearchParams({
      "name" : name,
      "surname":surname,
      "email":email,
      "phone":phone,
      "message":message, 
    });
    
  //   var str = Object.keys(data).map(function(key){ 
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]); 
  // }).join('&');

    fetch('https://fathomless-mountain-84781.herokuapp.com/submission',
    {
     method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: data.toString()
    })
         .then(function(response){
          // response.json()
         });
        // .then(res => setPostId(res.id));
  }

 const handleOnSubmit = (e) => {
    e.preventDefault();
    submitForm(e);
    e.target.reset()
  };

 const [change, setChange] = useState(true);
 
 const handleCheck = (e) => {
  setChange(!change)
}

  return (
    <>
    <OnResteEnContact>On reste en contact 👋</OnResteEnContact>
    <OnResteEnContactPrecisions>N'hésitez pas à nous solliciter !</OnResteEnContactPrecisions>
    <OnResteEnContactPrecisions>Nos équipes se feront un plaisir de vous faire une démo de notre technologie et des capacités de notre plateforme</OnResteEnContactPrecisions>
    <formdiv>
      <Form style={{margin:'2%'}} onSubmit={handleOnSubmit}>
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          label='Contact'
          name='user_name'
          placeholder='Nom ...'
          required
          icon='user circle'
          iconPosition='left'
        />
        <Form.Field
          id='form-input-control-first-name'
          control={Input}
          // label='Prenom'
          name='user_surname'
          placeholder='Prenom ...'
          // required
          icon='user circle'
          iconPosition='left'
        />
        <Form.Field
          id='form-input-control-email'
          control={Input}
          label='Email'
          name='user_email'
          placeholder='Email ...'
          required
          icon='mail'
          iconPosition='left'
        />
        <Form.Field
          id='form-input-control-phone'
          control={Input}
          // label='Prenom'
          name='user_phone'
          placeholder='contact ...'
          // required
          icon='phone'
          iconPosition='left'
        />

        <Form.Field
          id='form-textarea-control-opinion'
          control={TextArea}
          label='Message'
          name='user_message'
          placeholder='Message ...'
          required
        />
        <Checkbox onChange={handleCheck} label='En cliquant sur le bouton "Envoyer", vous reconnaissez que IA Immo traitera les données personnelles fournies dans ce formulaire afin de répondre à votre demande.' />
        <br></br>
        <br></br>
        <Button style={{ userSelect: "none", width:'100%' }} primary disabled={change} type='submit'>Envoyer</Button>
      </Form>
    </formdiv>
    </>
  );
}


  // return (
  //   <>
  //     <InfoSection {...homeObjOne} />
  //   </>
  // );

export default SignUp;