import React from 'react';
import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom' 
import GlobalStyles from './globalStyles'
import { Navbar, Footer } from './components';
import Home from './pages/HomePage/Home';
import Services from './pages/Services/Services';
import Products from './pages/Products/Products';
import SignUp from './pages/SignUp/SignUp';
import ScrollToTop from './components/ScrollToTop';
import { FirebaseAnalytics } from './myfirebase';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";


function App() {

  const handleAcceptCookie = () => {
      // if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      //   initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      // }
  }

const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

 useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
      <Router>
          <FirebaseAnalytics/>
          <GlobalStyles />
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/services' component={Services} />
            {/* <Route path='/products' component={Products} /> */}
            <Route path='/sign-up' component={SignUp} />
          </Switch>
          <Footer />
        <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
         buttonText="J'accepte"
         declineButtonText="Je refuse"
         buttonStyle={{ userSelect: "none", background : "#7FFF00", fontSize: "13px" }}
      >
        IA IMMO utilise des cookies pour améliorer votre expérience
      </CookieConsent>      </Router>
  );
}

export default App;
