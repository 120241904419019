import React from 'react'
import {useState} from 'react'
import {InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img} from './InfoSection.elements'
import { Container, Button,ButtonVideo,MyModal } from '../../globalStyles'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal"
import videoiaimmo from "../../video/IAImmo2.mp4"
import styled from 'styled-components';
import Video from 'react-responsive-video'

 const InfoSection = ({ 
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    buttonVideo,
    img,
    alt,
    imgStart,
    start
}) => {
    const [isOpen, setOpen] = useState(false)
    const [active, setActive] = useState(false);


    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                            <TopLine style={{ userSelect: "none" }} lightTopLine={lightTopLine}>{topLine}</TopLine>
                            <Heading style={{ userSelect: "none" }}  lightText={lightText}>{headline}</Heading>
                            <Subtitle style={{ userSelect: "none" }} lightTextDesc={lightTextDesc}>{description}</Subtitle>
                            <Link to='/sign-up'>
                            <Button style={{ userSelect: "none" }} big fontBig primary={primary}>
                                {buttonLabel}
                            </Button>
                            </Link>
                            &nbsp;&nbsp;
                            {buttonVideo!=='' &&
                            <React.Fragment>
                            <Button style={{ userSelect: "none" }} big fontBig primary={primary} onClick={()=> setActive(true)}>
                                {buttonVideo}
                            </Button>
                            <Modal 
                                open={active} 
                                onClose={()=> setActive(false)} 
                                center 
                                closeOnEsc={true} 
                                showCloseIcon={true}
                            >
                                <Video 
                                    //muted={false} 
                                    margin={0} 
                                    frameBorder={"0"} 
                                    mp4={videoiaimmo} 
                                    height={[320, 400, 480, 560].map(n => n + 'px')} 
                                    objectFit={`contain`} 
                                    controls 
                                    autoplay
                                    css={{ background: "transparent"}}
                                />
                            </Modal>
                            </React.Fragment>
                            }

                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                        <ImgWrapper start={start}>
                            <Img src={img} alt={alt} />
                        </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection;