// JavaScript
// src.firebase.js
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { initializeApp } from "firebase/app"
import {  getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBMzELjS69HIDBTum0LW_rKPnuTFNTBIZE",
    authDomain: "iaimmo.firebaseapp.com",
    projectId: "iaimmo",
    storageBucket: "iaimmo.appspot.com",
    messagingSenderId: "725395612986",
    appId: "1:725395612986:web:6ff2bc6fbcab656c5b9c03",
    measurementId: "G-RP6RYLK46J"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
// const db = getFirestore(app)
const analytics = getAnalytics(app);


const logevt = (message) => {
 logEvent(analytics,message);
}


export function FirebaseAnalytics() {
    let location = useLocation();
    useEffect(() => {
        const page_path = location.pathname + location.search;
        logevt("page_view", { page_path });
    }, [location]);
    return null;
}
