import React from 'react';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../globalStyles';
import agentImage from '../../images/new/Gagnez la confiance de vos futurs mandats-513x341.jpg';
import investisseurImage from '../../images/new/Investisseur.jpg';
import promoteurImage from '../../images/new/Promoteur.jpg';
import {
  PricingCard,
  PricingCardCost, PricingCardFeature, PricingCardFeatures,
  PricingCardIcon, PricingCardInfo, PricingCardMonth, PricingCardPlan,
  PricingCardPlan2,
  PricingContainer, PricingHeading, PricingSection, PricingWrapper, PromotionImage
} from './Pricing.elements';


 const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading style={{ userSelect: "none" }}>Nos Services</PricingHeading>
          <PricingContainer>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                <PricingCardIcon>
                <PromotionImage style={{ userSelect: "none" }}  src={promoteurImage}/>
                  {/* <GiRock /> */}
                </PricingCardIcon>
                <PricingCardPlan style={{ userSelect: "none" }} >Mandataire</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature style={{ userSelect: "none" }} >(Particulier et immobilier d'entreprise)</PricingCardFeature>
                </PricingCardFeatures>
                <PricingCardCost style={{ userSelect: "none" }} >
                  12€
                  <PricingCardMonth style={{ userSelect: "none" }} >/mois</PricingCardMonth>
                </PricingCardCost>
                {/* <PricingCardLength>mensuels</PricingCardLength> */}
                <PricingCardFeatures>
                  <PricingCardFeature style={{ userSelect: "none" }} >C'est l'ouverture de la chasse!</PricingCardFeature>
                  {/* <PricingCardFeature>100 New Users</PricingCardFeature>
                  <PricingCardFeature>$10,000 Budget</PricingCardFeature>
                  <PricingCardFeature>Retargeting analytics</PricingCardFeature> */}
                </PricingCardFeatures>
                <Button style={{ userSelect: "none" }} primary>Essai gratuit</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                <PricingCardIcon>
                  {/* <GiCrystalBars /> */}
                  <PromotionImage style={{ userSelect: "none" }} src={agentImage}/>
                </PricingCardIcon>
                <PricingCardPlan style={{ userSelect: "none" }} >Agence immobilière</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature style={{ userSelect: "none" }} >(Particulier et immobilier d'entreprise)</PricingCardFeature>
                </PricingCardFeatures>
                <PricingCardCost style={{ userSelect: "none" }} >
                  29 €
                  <PricingCardMonth style={{ userSelect: "none" }} >/mois</PricingCardMonth>
                </PricingCardCost>
                {/* <PricingCardLength>mensuels</PricingCardLength> */}
                <PricingCardFeatures>
                  <PricingCardFeature style={{ userSelect: "none" }} >Développez votre chiffre d'affaire!</PricingCardFeature>
                  {/* <PricingCardFeature>1000 New Users</PricingCardFeature>
                  <PricingCardFeature>$50,000 Budget</PricingCardFeature>
                  <PricingCardFeature>Lead Gen Analytics</PricingCardFeature> */}
                </PricingCardFeatures>
                <Button style={{ userSelect: "none" }} primary>Essai gratuit</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                <PricingCardIcon>
                  {/* <GiCutDiamond /> */}
                  <PromotionImage style={{ userSelect: "none" }} src={investisseurImage}/>
                </PricingCardIcon>
                <PricingCardPlan2 style={{ userSelect: "none" }} >Foncière & Société d'investissement immobilier</PricingCardPlan2>
                <PricingCardFeatures>
                  <PricingCardFeature style={{ userSelect: "none" }} >&nbsp;</PricingCardFeature>
                </PricingCardFeatures>
                <PricingCardCost style={{ userSelect: "none" }} >
                  Sur devis
                  <PricingCardMonth style={{ userSelect: "none" }} >&nbsp;</PricingCardMonth>
                </PricingCardCost>
                {/* <PricingCardLength>mensuels</PricingCardLength> */}
                <PricingCardFeatures>
                <PricingCardFeature style={{ userSelect: "none" }} >Identifiez les biens à rendement élevés!</PricingCardFeature>
                  {/* <PricingCardFeature>Unlimited Users</PricingCardFeature>
                  <PricingCardFeature>Unlimited Budget</PricingCardFeature>
                  <PricingCardFeature>24/7 Support</PricingCardFeature> */}
                </PricingCardFeatures>
                <Button style={{ userSelect: "none" }} primary>Essai gratuit</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}

export default Pricing