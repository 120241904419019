export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    // topLine: 'Marketing Agency',
    headline: 'La plateforme des professionnels de l\'immobilier',
    description:
      'IA Immo est une "smart map" prédictive et intuitive qui réunit toutes les connaissances immobilières dont vous avez besoin.',
    buttonLabel: 'Demander une démo',
    buttonVideo: 'Video',
    imgStart: '',
    img: require('../../images/new/1.jpg'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    // topLine: 'Sarah Jeni',
    headline:
      'Ayez toujours une longueur d’avance',
    description:
      "Votre champ d’exploration est désormais sans limite pour la prospection en directe de propriétaire vendeur ou la prospection de zones à fort potentielles de ventes",
    buttonLabel: 'Demander une démo',
    buttonVideo: '',
    imgStart: 'start',
    img: require('../../images/new/Ayez toujours une longueur d\'avance.PNG'),
    alt: 'Vault',
    start: 'true'
  };
  

  export const homeObjThree = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    // topLine: 'Instant Setup',
    headline: 'Optimiser votre temps',
    description:
      'IA Immo centralise et réactualise chaque seconde les informations les plus pertinentes du marché pour vous les rendre accessibles en un seul clic',
    buttonLabel: 'Demander une démo',
    buttonVideo: '',
    imgStart: '',
    img: require('../../images/new/Optimisez votre temps.jpg'),
    alt: 'Vault',
    start: ''
  };
  

  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,    // topLine: 'Secure Database',
    headline: 'L\’expert de la situation, c’est vous !',
    description:
      'Fournissez des rapports automatiques d’analyses comparatives, des avis de valeurs pertinents avant ou après la visite des biens auprès de vos clients.',
    buttonLabel: 'Demander une démo',
    buttonVideo: '',
    imgStart: 'start',
    img: require('../../images/new/L\'expert de la situation c\'est vous.jpg'),
    alt: 'Vault',
    start: 'true'
  };
  

export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    // topLine: 'Secure Database',
    headline: 'Gagnez la confiance de vos futurs mandats !',
    description:
      'Grâce à IA Immo, offrez une expérience unique d’analyse des biens de vos clients par rapport au marché (géolocalisation, évolution du prix/m2, bien à vendre similaire, ...)',
    buttonLabel: 'Demander une démo',
    buttonVideo: '',
    imgStart: 'start',
    img: require('../../images/new/Gagnez la confiance de vos futurs mandats.jpg'),
    alt: 'Vault',
    start: 'true'
  };


export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,    // topLine: 'Secure Database',
    // topLine: 'Secure Database',
    headline: 'Et si on boostait vos leads ?',
    description: 'Transaction, mensualité locative ou rendement brut, nos modules d’estimations sont intégrés directement sur votre site.',
    buttonLabel: 'Demander une démo',
    buttonVideo: '',
    imgStart: 'start',
    img: require('../../images/new/Et si on boostait vos leads.jpg'),
    alt: 'Vault',
    start: 'true'
  };